<template>
    <div class="page-box page">
        <to-history :prop-message="'添加/编辑联系人'"></to-history> 
        <Add :userid="userid" :corpid="corpid" :clientId="clientId" ref="addFunc2"></Add>
    </div>
    
</template>

<script>
import addL from '../../../components/addLinkman'  
export default {
    components: {
        'Add': addL  //将别名demo 变成 组件 Demo
    },
    data() {
        return {
            userid: "",
            corpid: "",
            clientId: "",
        }
    },
    mounted: function(){
        let query = this.$route.query;
        this.userid = query.userid;
        this.corpid = query.corpid;
        this.clientId = query.id;
        if(query.lid){
            this.$refs.addFunc2.getDetail(query.lid);
        }
    },
}

</script>
<style>
    .page-box{
        height: 100vh;
        background-color: #fff !important;
        padding: 15px;
    }
    .save .ant-btn {
        width: 27rem;
        margin-left: calc((100vw - 27rem)/2);
        margin-bottom: 30px;
    }
</style>
